import React from 'react';

const App: React.FC = () => {
  return (
    <div>
      <h1 
        style={{
          textAlign: 'center'
      }}>
        Website under construction</h1>
      <div 
        style={{
          margin: '0 auto',
          width: '855px'
        }}>
        <br /><br /><br /><br />

        When completed, this site will be a tool for developers of all skill levels to learn about Ethereum and its development ecosystem.

        <br /><br />

        Developers will be able to share ideas and provide advice to other developers in this space. The scope of the discussions inclue Smart Contracts, wallets, Dapps, tools, and other related items.

        <br /><br />

        There will be a forum for developers to ask and answer questions, articles written to help expand the reach of Ethereum based development, tutrials for all levels of developers, and a place for community members to discuss the future of the Ethereum network.

      </div>
      <h3 
        style={{
          position: 'fixed',
          textAlign: 'center',
          bottom: '5px',
          width: '100%'
        }}>
        This is an independent website for developers and it is not affiliated with or endorsed by Stiftung Ethereum (Foundation Ethereum), owners of the Ethereum® trademark.
      </h3>
    </div>
  );
}

export default App;
